import React from 'react';
import './LanguageSelector.css';

const LanguageSelector = ({ currentLanguage, onLanguageChange }) => {
  return (
    <div className="language-selector">
      <img
        src="/midia/flags/br.svg"
        alt="Português"
        className={`flag ${currentLanguage === 'pt' ? 'active' : ''}`}
        onClick={() => onLanguageChange('pt')}
      />
      <img
        src="/midia/flags/us.svg"
        alt="English"
        className={`flag ${currentLanguage === 'en' ? 'active' : ''}`}
        onClick={() => onLanguageChange('en')}
      />
      <img
        src="/midia/flags/es.svg"
        alt="Español"
        className={`flag ${currentLanguage === 'es' ? 'active' : ''}`}
        onClick={() => onLanguageChange('es')}
      />
    </div>
  );
};

export default LanguageSelector;
