import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.css';
import SurveyVerginia from './survey/SurveyVerginia.jsx';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import SurveyAdm from './views/SurveyAdm.jsx';
import SurveyView from './survey/SurveyView.jsx';
import SurveyEditor from './survey/SurveyEditor.jsx';
import SurveyCreate from './survey/SurveyCreate.jsx';
import ThankYouPage from './components/ThankYouPage';
import Main from './views/Main';
import AuthProvider from './components/Auth/AuthContext';
import Login from './components/Auth/Login';
import RequireAuth from './components/Auth/RequireAuth';
import Chatbot from './chatbot/Chatbot';
import SourceSkillsResult from './source_skills/SourceSkillsResult';
import SourceSkillsResultAi from './source_skills/SourceSkillsResultAi';
import SourceSkillsGroupResult from './source_skills/SourceSkillsGroupResult';
import SourceSkillsAuthProvider from './components/Auth/SourceSkillsAuthContext';
import SourceSkillsLogin from './components/Auth/SourceSkillsLogin';
import SourceSkillsProtectedRoute from './components/Auth/SourceSkillsProtectedRoute';
import StandaloneSurveyView from './survey/StandaloneSurveyView';
import { LanguageProvider } from './contexts/LanguageContext';

const AppRoutes = () => (
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        {/* Source Skills Authentication */}
        <Route path="/source-skills-login" element={<SourceSkillsLogin />} />
        <Route path="/" element={<SourceSkillsProtectedRoute element={<SurveyView id_survey={1} />} />} />

        {/* Admin Authentication */}
        <Route path='/login' element={<Login />} />
        <Route path="/Admin/*" element={<RequireAuth><SurveyAdm /></RequireAuth>} />
        <Route path="/Survey/View/:id_survey" element={<RequireAuth><SurveyView /></RequireAuth>} />
        <Route path="/Survey/View/:id_survey/Obrigado" element={<RequireAuth><ThankYouPage /></RequireAuth>} />
        <Route path="/Survey/Editor/:id_survey" element={<RequireAuth><SurveyEditor /></RequireAuth>} />
        <Route path="/Survey/Create" element={<RequireAuth><SurveyCreate /></RequireAuth>} />

        {/* Other Routes */}
        <Route path="/Report" element={<SourceSkillsProtectedRoute element={<SourceSkillsResult />} />} />
        <Route path="/ReportAI" element={<SourceSkillsProtectedRoute element={<SourceSkillsResultAi />} />} />
        <Route path="/GroupReport" element={<SourceSkillsProtectedRoute element={<SourceSkillsGroupResult />} />} />
        <Route path='/Chatbot' element={<Chatbot />} />
        <Route path="/standalone-survey" element={<StandaloneSurveyView id_survey={1} />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

const AppWrapper = () => (
  <AuthProvider>
    <SourceSkillsAuthProvider>
      <AppRoutes />
    </SourceSkillsAuthProvider>
  </AuthProvider>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <LanguageProvider>
            <AppWrapper />
        </LanguageProvider>
    </React.StrictMode>
);

reportWebVitals();
