import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import './MainLayout.css';
import { sourceSkillsAuth } from '../components/Auth/firebaseSourceSkillsConfig';

export default function MainLayout({ children }) {
    // Função para verificar se é mobile
    const isMobile = () => window.innerWidth <= 768;
    
    // Estado inicial baseado no tamanho da tela
    const [isExpanded, setIsExpanded] = useState(!isMobile());
    const [language, setLanguage] = useState(() => {
        return localStorage.getItem('surveyLanguage') || 'pt';
    });
    const navigate = useNavigate();

    // Efeito para lidar com redimensionamento
    useEffect(() => {
        // Função para atualizar o estado do sidebar
        const handleResize = () => {
            const mobile = isMobile();
            if (mobile) {
                setIsExpanded(false);
            }
        };

        // Adiciona listener para redimensionamento
        window.addEventListener('resize', handleResize);
        
        // Executa verificação inicial
        handleResize();

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Efeito para autenticação
    useEffect(() => {
        const isAuthenticated = sourceSkillsAuth.currentUser !== null;
        if (!isAuthenticated) {
            navigate('/source-skills-login');
        }
    }, [navigate]);

    const handleLanguageChange = (newLanguage) => {
        localStorage.setItem('surveyLanguage', newLanguage);
        setLanguage(newLanguage);
        
        if (sourceSkillsAuth.currentUser) {
            sourceSkillsAuth.currentUser.updateProfile({
                language: newLanguage
            }).catch(error => {
                console.error('Error updating language preference:', error);
            });
        }

        window.dispatchEvent(new CustomEvent('languageChange', { detail: newLanguage }));
    };

    return (
        <div className="main-layout">
            <Sidebar 
                currentLanguage={language}
                onLanguageChange={handleLanguageChange}
                isExpanded={isExpanded}
                onToggle={() => setIsExpanded(!isExpanded)}
            />
            <div className={`main-content ${!isExpanded ? 'expanded' : ''}`}>
                {React.Children.map(children, child => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, { language });
                    }
                    return child;
                })}
            </div>
        </div>
    );
}
