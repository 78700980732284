import { FetchDataBlock } from './DataBlock';

interface RequestData {
    context: string;
    guid_answer?: string;
    token?: string;
    return_type: string;
    dimensions: string[];
    filters: {question_name: string[]}[];
}

interface ResponseData {
    original_value: string[];
    [key: string]: any;
}

export default async function fetchAiDimensionRecommendation(guid: string): Promise<any> {
    const requestData: RequestData = {
        "context": "answer",
        "guid_answer": guid,
        "return_type": "column",
        "dimensions": ["original_value"],
        "filters": [{
            "question_name": ["ai_dimensoes_recomendacao"]
        }],
    };

    try {
        // Apenas alterando a URL para a correta
        const response = await fetch('https://j5w5ag7110.execute-api.us-east-1.amazonaws.com/lime-pi-fetch-data-block', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const jsonData: ResponseData = await response.json();
        console.log("Response from API:", jsonData);

        if (jsonData.original_value && jsonData.original_value.length > 0) {
            return jsonData.original_value[0];
        }
        return jsonData;
    } catch (error) {
        console.error("Error fetching AI dimension recommendation:", error);
        throw error;
    }
}
