import React from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { useLanguage } from '../contexts/LanguageContext';
import { reportTranslations } from '../translations/reportTranslations';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

function CompetenciasRadar({ dimensao_valores, dimensao_colunas }) {
    const { language } = useLanguage();
    const translations = reportTranslations[language] || reportTranslations.pt;

    // Função para traduzir os nomes das competências
    const translateCompetency = (competency) => {
        const normalizedCompetency = competency.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        
        const competencyMap = {
            "abertura": translations.competencies.openness,
            "amizade": translations.competencies.friendship,
            "confianca genuina": translations.competencies.genuineTrust,
            "pertencimento": translations.competencies.belonging,
            "celebracao": translations.competencies.celebration,
            "ser coletivo": translations.competencies.collectiveBeing,
            "flexibilidade": translations.competencies.flexibility,
            "cocriacao": translations.competencies.cocreation,
            "altruismo": translations.competencies.altruism,
            "autodeterminacao": translations.competencies.selfDetermination,
            "lucidez": translations.competencies.lucidity,
            "ser individual": translations.competencies.individualBeing,
            "autoexpressao": translations.competencies.selfExpression,
            "convergencia": translations.competencies.convergence,
            "proposito": translations.competencies.purpose,
            "interdependencia": translations.competencies.interdependence,
            "concentracao": translations.competencies.concentration,
            "mobilizacao": translations.competencies.mobilization,
            "clareza": translations.competencies.clarity,
            "acesso": translations.competencies.access,
            "reciprocidade": translations.competencies.reciprocity,
            "compartilhamento": translations.competencies.sharing,
            "recomendacao": translations.competencies.recommendation
        };

        const normalizedKey = Object.keys(competencyMap).find(key => 
            key === normalizedCompetency || 
            key === competency.toLowerCase()
        );

        return normalizedKey ? competencyMap[normalizedKey] : competency;
    };

    const translatedLabels = dimensao_colunas.map(competency => {
        const translation = translateCompetency(competency);
        return translation;
    });

    const data = {
        labels: translatedLabels,
        datasets: [
            {
                data: dimensao_valores,
                backgroundColor: 'rgba(179, 143, 31, 0.2)',
                borderColor: 'rgba(179, 143, 31, 1)',
                borderWidth: 1,
                pointBackgroundColor: 'rgba(179, 143, 31, 1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(179, 143, 31, 1)',
                pointRadius: 3,
                pointHoverRadius: 5
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            r: {
                min: 0,
                max: 10,
                beginAtZero: true,
                ticks: {
                    stepSize: 2,
                    font: {
                        size: window.innerWidth < 768 ? 8 : 11
                    }
                },
                pointLabels: {
                    font: {
                        size: window.innerWidth < 768 ? 8 : 11
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false
            }
        }
    };

    return (
        <div style={{
            width: '100%',
            maxWidth: '100%',
            margin: '0 auto',
            padding: window.innerWidth < 768 ? '0' : '20px',
            height: window.innerWidth < 768 ? '350px' : '500px'
        }}>
            <Radar data={data} options={options} />
        </div>
    );
}

export default CompetenciasRadar;
