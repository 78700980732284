import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { SourceSkillsAuthContext } from './SourceSkillsAuthContext';

const SourceSkillsProtectedRoute = ({ element }) => {
  const { user } = useContext(SourceSkillsAuthContext);
  const location = useLocation();
  
  if (!user) {
    // Salva a URL atual incluindo os parâmetros no localStorage
    localStorage.setItem('redirectAfterLogin', `${location.pathname}${location.search}`);
    return <Navigate to="/source-skills-login" />;
  }

  return element;
};

export default SourceSkillsProtectedRoute;
