import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useLanguage } from '../contexts/LanguageContext';
import { reportTranslations } from '../translations/reportTranslations';

// Registrar os componentes necessários do Chart.js
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function DimensaoChart({ dimensao_valores, dimensao_colunas }) {
    const { language } = useLanguage();
    const translations = reportTranslations[language] || reportTranslations.pt;

    // Função para traduzir os nomes das dimensões
    const translateDimension = (dimension) => {
        // Normaliza o texto para comparação
        const normalizedDimension = dimension.trim().toLowerCase();
        
        const dimensionMap = {
            "alinhamento para resultado": translations.dimensions.alignment,
            "alinhamento para resultados": translations.dimensions.alignment,
            "alinhamento para resultado ": translations.dimensions.alignment, // com espaço extra
            "alinhamento para resultados ": translations.dimensions.alignment, // com espaço extra
            "autoconsciência em prol do coletivo": translations.dimensions.awareness,
            "criação coletiva": translations.dimensions.creation,
            "compartilhamento de conhecimento": translations.dimensions.sharing
        };

        // Usa o texto normalizado para buscar a tradução
        return dimensionMap[normalizedDimension] || dimensionMap[dimension] || dimension;
    };

    // Traduzir os nomes das dimensões
    const translatedLabels = dimensao_colunas.map(dimension => {
        const translation = translateDimension(dimension.toLowerCase());
        console.log(`Translating "${dimension}" to "${translation}"`); // Debug log
        return translation;
    });

    const data = {
        labels: translatedLabels,
        datasets: [
            {
                data: dimensao_valores,
                backgroundColor: 'rgba(179, 143, 31, 0.8)',
                borderColor: 'rgba(179, 143, 31, 1)',
                borderWidth: 1,
                barThickness: 35,
                maxBarThickness: 45
            },
        ],
    };

    const options = {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 20,
                right: 25,
                bottom: 20,
                left: 25
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                titleColor: '#1a1817',
                bodyColor: '#1a1817',
                bodyFont: {
                    family: "'Inter', sans-serif",
                    size: 14
                },
                padding: 12,
                boxPadding: 6,
                borderColor: 'rgba(0, 0, 0, 0.1)',
                borderWidth: 1,
                callbacks: {
                    label: function(context) {
                        const dimension = dimensao_colunas[context.dataIndex];
                        const value = context.raw.toFixed(2);
                        return `${translateDimension(dimension)}: ${value}`;
                    }
                }
            }
        },
        scales: {
            x: {
                beginAtZero: true,
                max: 10,
                grid: {
                    display: true,
                    drawBorder: true,
                    color: 'rgba(0, 0, 0, 0.1)',
                },
                ticks: {
                    stepSize: 2,
                    font: {
                        family: "'Inter', sans-serif",
                        size: 12
                    },
                    padding: 8
                }
            },
            y: {
                grid: {
                    display: false,
                    drawBorder: true,
                },
                ticks: {
                    font: {
                        family: "'Inter', sans-serif",
                        size: 13,
                        weight: '500'
                    },
                    padding: 12
                }
            }
        }
    };

    return (
        <div style={{ 
            height: '300px', 
            width: '100%',
            padding: '20px',
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
            margin: '20px 0'
        }}>
            <Bar data={data} options={options} />
        </div>
    );
}

export default DimensaoChart;
