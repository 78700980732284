import '../layouts/lime.css';
import React from 'react';
import * as Survey from "survey-core";
import * as SurveyReact from "survey-react-ui";
import "survey-core/modern.min.css";
import "survey-core/survey.i18n.min.js";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { ThreeCircles } from 'react-loader-spinner'
import ErrorToast from '../components/ErrorToast.jsx'
import useSurvey from '../components/Surveys/useSurvey.js';
import { Helmet } from "react-helmet";
import useModal from '../components/Surveys/useModal.js';
import { useState } from "react";

export default function StandaloneSurveyView({ id_survey: idSurveyProp }) {
    const navigate = useNavigate();
    const { id_survey: idSurveyParam } = useParams();
    const id_survey = idSurveyProp || idSurveyParam;
    const { json, loading, error, isModalOpen, handleValueChanged, handlePageChanged, handleComplete } = useSurvey(id_survey, navigate);
    const [modalText] = useState("Obrigado por participar. Aguarde enquanto o formulário está salvando...");
    Survey.StylesManager.applyTheme("modern");
    const surveyModel = new Survey.Model(json);
    const [Modal] = useModal();

    surveyModel.onValueChanged.add(handleValueChanged);
    surveyModel.onCurrentPageChanged.add(handlePageChanged);
    surveyModel.onComplete.add(handleComplete);

    return (
        <div className="standalone-survey">
            <Helmet>
                <link rel="stylesheet"
                    href={`${process.env.REACT_APP_LIME_API_URL}/survey/css/${id_survey}`} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            </Helmet>
            <div className={loading ? "hidden" : "bg-survey"} >
                {!loading && <SurveyReact.Survey model={surveyModel} />}
            </div>
            <div className={"" + (!loading ? " hidden " : "centralizar-vertical-horizontal")}>
                <ThreeCircles color="#F5D728" height={80} width={80} />
            </div>
            {error && <ErrorToast message={error} />}
            <Modal
                title="Aguarde..."
                content={`${modalText}`}
                isLoading={true}
            />
        </div>
    );
}
