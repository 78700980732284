import '../layouts/App.css';
import React, { useState, useEffect, useRef } from 'react';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { useNavigate } from 'react-router-dom';
import 'survey-core/defaultV2.min.css';
import sourceSkillsForm from './source_skills_form.json';
import './source_skills_personalization.css';
import MainLayout from '../layouts/MainLayout';
import useSurvey from '../components/Surveys/useSurvey';

export default function SurveyView() {
    const navigate = useNavigate();
    const [language, setLanguage] = useState(() => {
        return localStorage.getItem('surveyLanguage') || 'pt';
    });
    const { json, loading, error, isModalOpen, handleValueChanged, handlePageChanged, handleComplete, setIsModalOpen } = useSurvey(1, navigate);
    const [survey, setSurvey] = useState(null);
    const surveyModelRef = useRef(null);
    const surveyState = useRef(null);

    useEffect(() => {
        const handleLanguageChange = (event) => {
            const newLanguage = event.detail;
            setLanguage(newLanguage);
            if (surveyModelRef.current) {
                surveyModelRef.current.locale = newLanguage === 'pt' ? 'pt-br' : newLanguage;
            }
        };

        window.addEventListener('languageChange', handleLanguageChange);

        return () => {
            window.removeEventListener('languageChange', handleLanguageChange);
        };
    }, []);

    // Inicialização do survey - movida para um useEffect separado que roda apenas uma vez
    useEffect(() => {
        if (surveyModelRef.current) return; // Evita reinicialização

        const surveyModel = new Model(sourceSkillsForm);
        surveyModelRef.current = surveyModel;

        // Restaurar estado anterior se existir
        if (surveyState.current) {
            surveyModel.currentPageNo = surveyState.current.currentPage;
            surveyModel.data = surveyState.current.data;
        }

        // Custom theme configuration
        surveyModel.applyTheme({
            cssVariables: {
                "--sjs-general-forecolor": "#1a1817",
                "--sjs-general-backcolor": "#fcfaf3",
                "--sjs-header-backcolor": "#ffffff",
                "--sjs-header-forecolor": "#1a1817",
                "--sjs-body-backcolor": "#ffffff",
                "--sjs-body-forecolor": "#1a1817",
                "--sjs-base-unit": "8px",
                "--sjs-corner-radius": "4px",
                "--sjs-button-backcolor": "#b38f1f",
                "--sjs-button-forecolor": "#1a1817",
                "--sjs-button-hover-backcolor": "#9a7b1a",
                "--sjs-button-hover-forecolor": "#ffffff",
                "--background": "#fcfaf3",
                "--text-color": "#1a1817",
                "--header-color": "#1a1817",
                "--button-color": "#b38f1f",
            },
            themeName: "defaultV2"
        });

        surveyModel.locale = language === 'pt' ? 'pt-br' : language;
        setSurvey(surveyModel);
    }, []); // Dependências vazias - roda apenas uma vez

    // Atualização dos handlers em um useEffect separado
    useEffect(() => {
        if (!surveyModelRef.current) return;

        // Remover handlers antigos para evitar duplicação
        surveyModelRef.current.onValueChanged.clear();
        surveyModelRef.current.onCurrentPageChanged.clear();
        surveyModelRef.current.onComplete.clear();

        // Adicionar novos handlers
        surveyModelRef.current.onValueChanged.add(handleValueChanged);
        surveyModelRef.current.onCurrentPageChanged.add(handlePageChanged);
        surveyModelRef.current.onComplete.add(handleComplete);

    }, [handleValueChanged, handlePageChanged, handleComplete]);

    // Atualização do idioma em um useEffect separado
    useEffect(() => {
        if (surveyModelRef.current) {
            surveyModelRef.current.locale = language === 'pt' ? 'pt-br' : language;
        }
    }, [language]);

    return (
        <MainLayout>
            <div className="bg-survey">
                <div className="survey-container source-skills-survey">
                    {error && (
                        <div className="error-message" style={{
                            color: 'red',
                            padding: '10px',
                            margin: '10px 0',
                            backgroundColor: '#ffe6e6',
                            borderRadius: '4px'
                        }}>
                            {error}
                        </div>
                    )}
                    {survey && (
                        <Survey 
                            model={survey}
                        />
                    )}
                </div>
            </div>
        </MainLayout>
    );
}
