import React, { useState, useEffect, useRef } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import FetchAiDimensionRecommendation from '../../api/FetchAiDimensionRecommendation.tsx';
import { useLanguage } from '../../contexts/LanguageContext';
import { reportTranslations } from '../../translations/reportTranslations';

function DimensoesRecomendacoesLoader({ context_filter }) {
  const [loading_recomendacoes, setLoadingRecomendacoes] = useState(true);
  const [recomendacoes_html, setRecomendacoesHtml] = useState(null);
  const fetchRetryCount = useRef(0);
  const { language } = useLanguage();
  const translations = reportTranslations[language] || reportTranslations.pt;

  useEffect(() => {
    let retryFetchInterval;
    let isMounted = true;

    async function fetchData() {
      try {
        console.log('Fetching recommendations with params:', {
          context_filter,
          language,
          attempt: fetchRetryCount.current + 1
        });

        const recomendacoes_html = await FetchAiDimensionRecommendation(context_filter);
        console.log('API Response:', {
          received: !!recomendacoes_html,
          length: recomendacoes_html?.length,
          content: recomendacoes_html?.substring(0, 100) + '...' // Log primeiros 100 caracteres
        });

        if (!isMounted) {
          console.log('Component unmounted, skipping update');
          return;
        }

        if (recomendacoes_html && typeof recomendacoes_html === 'string' && recomendacoes_html.trim().length > 0) {
          console.log('Valid recommendations received, updating state');
          setRecomendacoesHtml(recomendacoes_html);
          setLoadingRecomendacoes(false);
          fetchRetryCount.current = 0;
          if (retryFetchInterval) {
            clearTimeout(retryFetchInterval);
          }
        } else if (fetchRetryCount.current < 24) {
          console.log('Empty or invalid response, retrying...');
          const seconds = 15;
          fetchRetryCount.current += 1;
          retryFetchInterval = setTimeout(fetchData, seconds * 1000);
        } else {
          console.log('Max retries reached');
          setLoadingRecomendacoes(false);
          setRecomendacoesHtml(`<p>${translations.maxRetriesError}</p>`);
        }
      } catch (error) {
        console.error('Error details:', {
          message: error.message,
          stack: error.stack,
          attempt: fetchRetryCount.current + 1
        });

        if (!isMounted) return;

        if (fetchRetryCount.current < 24) {
          const seconds = 15;
          fetchRetryCount.current += 1;
          retryFetchInterval = setTimeout(fetchData, seconds * 1000);
        } else {
          setLoadingRecomendacoes(false);
          setRecomendacoesHtml(`<p>${translations.loadError}</p>`);
        }
      }
    }

    if (context_filter) {
      console.log('Starting fetch process with context_filter:', context_filter);
      setLoadingRecomendacoes(true);
      fetchData();
    } else {
      console.warn('No context_filter provided');
      setLoadingRecomendacoes(false);
      setRecomendacoesHtml(`<p>${translations.loadError}</p>`);
    }

    return () => {
      console.log('Cleanup: unmounting component');
      isMounted = false;
      if (retryFetchInterval) {
        clearTimeout(retryFetchInterval);
      }
    };
  }, [context_filter, language, translations]);

  return (
    <div>
      {loading_recomendacoes ? (
        <div className="loading-container">
          <ThreeCircles color="#F5D728" height={80} width={80} />
          <p className="loading-text">{translations.loadingAI}</p>
          <p className="loading-text">
            {translations.retryAttempt.replace('{current}', fetchRetryCount.current + 1).replace('{max}', '24')}
          </p>
        </div>
      ) : (
        recomendacoes_html ? (
          <div 
            dangerouslySetInnerHTML={{ __html: recomendacoes_html }}
            style={{ whiteSpace: 'pre-wrap' }}
          />
        ) : (
          <div className="error-message">
            {translations.error}
          </div>
        )
      )}
    </div>
  );
}

export default DimensoesRecomendacoesLoader;
