import React, { createContext, useState, useEffect, useContext } from 'react';
import { sourceSkillsAuth } from './firebaseSourceSkillsConfig';

export const SourceSkillsAuthContext = createContext();

// Hook personalizado para usar o contexto
export const useSourceSkillsAuth = () => {
  const context = useContext(SourceSkillsAuthContext);
  if (!context) {
    throw new Error('useSourceSkillsAuth must be used within a SourceSkillsAuthProvider');
  }
  return context;
};

const SourceSkillsAuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = sourceSkillsAuth.onAuthStateChanged(user => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const value = {
    user,
    loading
  };

  return (
    <SourceSkillsAuthContext.Provider value={value}>
      {!loading && children}
    </SourceSkillsAuthContext.Provider>
  );
};

export default SourceSkillsAuthProvider;
