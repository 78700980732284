export const translations = {
  pt: {
    login: 'Login',
    register: 'Registro',
    email: 'Email',
    password: 'Senha',
    displayName: 'Nome de exibição',
    alreadyHaveAccount: 'Já tem uma conta? Entre',
    needAccount: 'Precisa de uma conta? Registre-se',
    verifyEmail: 'Por favor, verifique seu email antes de fazer login.',
    registrationSuccess: 'Registro realizado com sucesso! Por favor, verifique seu email para confirmar sua conta.',
    invalidCredentials: 'Email ou senha inválidos',
    sourceSkillsLogin: 'Source Skills Login',
    continueWithGoogle: 'Continuar com Google',
    welcome: 'Bem-vindo(a)',
    selectLanguage: 'Selecione o idioma',
    logout: 'Sair',
    rememberMe: 'Permanecer conectado',
    forgotPassword: 'Esqueceu sua senha?',
    resetPasswordSuccess: 'Email de redefinição de senha enviado! Verifique sua caixa de entrada.',
    resetPasswordError: 'Erro ao enviar email de redefinição. Verifique se o email está correto.',
    slogan: 'Autoconsciência em direção ao Coletivo',
    welcomeMessage: 'Descubra seu potencial de colaboração através de 23 fatores essenciais para liderança e desenvolvimento.',
  },
  en: {
    login: 'Login',
    register: 'Register',
    email: 'Email',
    password: 'Password',
    displayName: 'Display Name',
    alreadyHaveAccount: 'Already have an account? Login',
    needAccount: 'Need an account? Register',
    verifyEmail: 'Please verify your email before logging in.',
    registrationSuccess: 'Registration successful! Please check your email to verify your account.',
    invalidCredentials: 'Invalid email or password',
    sourceSkillsLogin: 'Source Skills Login',
    continueWithGoogle: 'Continue with Google',
    welcome: 'Welcome',
    selectLanguage: 'Select language',
    logout: 'Logout',
    rememberMe: 'Stay signed in',
    forgotPassword: 'Forgot password?',
    resetPasswordSuccess: 'Password reset email sent! Check your inbox.',
    resetPasswordError: 'Error sending reset email. Please verify your email.',
    slogan: 'Self-awareness towards the Collective',
    welcomeMessage: 'Discover your collaboration potential through 23 essential factors for leadership and development.',
  },
  es: {
    login: 'Login',
    register: 'Registro',
    email: 'Correo electrónico',
    password: 'Contraseña',
    displayName: 'Nombre para mostrar',
    alreadyHaveAccount: '¿Ya tienes una cuenta? Inicia sesión',
    needAccount: '¿Necesitas una cuenta? Regístrate',
    verifyEmail: 'Por favor, verifica tu correo electrónico antes de iniciar sesión.',
    registrationSuccess: '¡Registro exitoso! Por favor, verifica tu correo electrónico para confirmar tu cuenta.',
    invalidCredentials: 'Correo electrónico o contraseña inválidos',
    sourceSkillsLogin: 'Source Skills Login',
    continueWithGoogle: 'Continuar con Google',
    welcome: 'Bienvenido(a)',
    selectLanguage: 'Seleccionar idioma',
    logout: 'Cerrar sesión',
    rememberMe: 'Mantener la sesión iniciada',
    forgotPassword: '¿Olvidó su contraseña?',
    resetPasswordSuccess: '¡Email de restablecimiento enviado! Revise su bandeja de entrada.',
    resetPasswordError: 'Error al enviar el email de restablecimiento. Verifique su email.',
    slogan: 'Autoconciencia hacia lo Colectivo',
    welcomeMessage: 'Descubre tu potencial de colaboración a través de 23 factores esenciales para el liderazgo y el desarrollo.',
  }
};
