export const reportTranslations = {
    pt: {
        title: "Relatório de Habilidades",
        loading: "Carregando seu relatório...",
        loadingAI: "Carregando recomendações por IA. Isso pode levar alguns minutos... Aguarde...",
        loadingDimensions: "Carregando análise das dimensões...",
        loadingFactors: "Carregando análise dos fatores...",
        loadingChart: "Carregando gráfico...",
        error: "Erro ao carregar o relatório",
        tryAgain: "Tente novamente",
        sections: {
            overview: "Visão Geral",
            details: "Detalhes",
            recommendations: "Recomendações"
        },
        skills: {
            technical: "Habilidades Técnicas",
            soft: "Habilidades Comportamentais",
            business: "Habilidades de Negócios"
        },
        levels: {
            beginner: "Iniciante",
            intermediate: "Intermediário",
            advanced: "Avançado",
            expert: "Especialista"
        },
        charts: {
            skillDistribution: "Distribuição de Habilidades",
            strengthAreas: "Áreas de Força",
            improvementAreas: "Áreas para Desenvolvimento"
        },
        sourceSkillsDescription: `Source Skills é um framework composto por 23 fatores, 
            que representa o potencial da colaboração com autoconsciência, na perspectiva da gestão, 
            da liderança e do desenvolvimento humano e organizacional.

            Source Skills é fruto de pesquisa acadêmica em negócios inovadores e gera valor em aplicações diversas, 
            voltadas a mentoria, educação corporativa e gestão de negócios, bem como em consultoria dirigida a diversos setores.

            Nesse diagnóstico, você encontra um retrato inicial desses 23 fatores, para que você possa refletir e 
            também potencializar a sua própria jornada de liderança e de autodesenvolvimento.

            Abraços,`,
        resultadoGeral: "Resultado Geral",
        indiceGeral: "Esse é o seu indice geral das habilidades Source Skills.",
        mapaDosFactors: "Mapa dos 23 Fatores",
        osFactors: "Os 23 Fatores",
        journeyMessage: "Sua jornada de alto desenvolvimento e colaboração está apenas começando...",
        contactButton: "Entre em contato com marcelo@collabsoul.com",
        maxRetriesError: "Não foi possível carregar as recomendações após várias tentativas. Por favor, tente novamente mais tarde.",
        loadError: "Erro ao carregar as recomendações. Por favor, tente novamente mais tarde.",
        retryAttempt: "Tentativa {current} de {max}",
        dimensions: {
            alignment: "Alinhamento para resultados",
            awareness: "Autoconsciência em prol do Coletivo",
            creation: "Criação coletiva",
            sharing: "Compartilhamento de Conhecimento"
        },
        dimensionsDescription: {
            alignment: "A dimensão \"Alinhamento para resultados\" nos impulsiona para engajar pessoas em torno de um objetivo para o alcance de um resultado almejado, gerando maior pertencimento e protagonismo.",
            awareness: "A dimensão \"Autoconsciência em prol do Coletivo\" nos convida a uma efetividade maior com os outros, começando por nós mesmos, em um \"lugar\" interno que nos habilita a agir com mais lucidez, coerência, autodeterminação e resiliência.",
            creation: "A dimensão \"Criação coletiva\" nos conecta com a criatividade e inovação, não só na dimensão cognitiva, mas principalmente na força do campo coletivo emergente.",
            sharing: "A dimensão \"Compartilhamento de Conhecimento\" nos conecta com o toque da conveniência a vantagem que podemos obter ao socializar informações, recursos e a expectativa de reciprocidade."
        },
        competencies: {
            openness: "Abertura",
            friendship: "Amizade",
            genuineTrust: "Confiança Genuína",
            belonging: "Pertencimento",
            celebration: "Celebração",
            collectiveBeing: "Ser Coletivo",
            flexibility: "Flexibilidade",
            cocreation: "Cocriação",
            altruism: "Altruísmo",
            selfDetermination: "Autodeterminação",
            lucidity: "Lucidez",
            individualBeing: "Ser Individual",
            selfExpression: "Autoexpressão",
            convergence: "Convergência",
            purpose: "Propósito",
            interdependence: "Interdependência",
            concentration: "Concentração",
            mobilization: "Mobilização",
            clarity: "Clareza",
            access: "Acesso",
            reciprocity: "Reciprocidade",
            sharing: "Compartilhamento",
            recommendation: "Recomendação"
        }
    },
    en: {
        title: "Skills Report",
        loading: "Loading your report...",
        loadingAI: "Loading AI recommendations. This may take a few minutes... Please wait...",
        loadingDimensions: "Loading dimensions analysis...",
        loadingFactors: "Loading factors analysis...",
        loadingChart: "Loading chart...",
        error: "Error loading report",
        tryAgain: "Try again",
        sections: {
            overview: "Overview",
            details: "Details",
            recommendations: "Recommendations"
        },
        skills: {
            technical: "Technical Skills",
            soft: "Soft Skills",
            business: "Business Skills"
        },
        levels: {
            beginner: "Beginner",
            intermediate: "Intermediate",
            advanced: "Advanced",
            expert: "Expert"
        },
        charts: {
            skillDistribution: "Skill Distribution",
            strengthAreas: "Strength Areas",
            improvementAreas: "Areas for Improvement"
        },
        sourceSkillsDescription: `Source Skills is a framework composed of 23 factors, 
            representing the potential for collaboration with self-awareness, from the perspective of management, 
            leadership, and human and organizational development.

            Source Skills is the result of academic research in innovative businesses and generates value in various applications, 
            focused on mentoring, corporate education, and business management, as well as consulting directed to various sectors.

            In this diagnosis, you find an initial portrait of these 23 factors, so that you can reflect and 
            also enhance your own leadership and self-development journey.

            Best regards,`,
        resultadoGeral: "Overall Result",
        indiceGeral: "This is your general index of Source Skills abilities.",
        mapaDosFactors: "Map of 23 Factors",
        osFactors: "The 23 Factors",
        journeyMessage: "Your journey of high development and collaboration is just beginning...",
        contactButton: "Contact marcelo@collabsoul.com",
        maxRetriesError: "Could not load recommendations after multiple attempts. Please try again later.",
        loadError: "Error loading recommendations. Please try again later.",
        retryAttempt: "Attempt {current} of {max}",
        dimensions: {
            alignment: "Alignment for Results",
            awareness: "Self-awareness for Collective Benefit",
            creation: "Collective Creation",
            sharing: "Knowledge Sharing"
        },
        dimensionsDescription: {
            alignment: "The \"Alignment for Results\" dimension drives us to engage people around a goal to achieve a desired result, generating greater belonging and protagonism.",
            awareness: "The \"Self-awareness for Collective Benefit\" dimension invites us to greater effectiveness with others, starting with ourselves, in an internal \"place\" that enables us to act with more lucidity, coherence, self-determination, and resilience.",
            creation: "The \"Collective Creation\" dimension connects us with creativity and innovation, not only in the cognitive dimension but mainly in the strength of the emerging collective field.",
            sharing: "The \"Knowledge Sharing\" dimension connects us with the touch of convenience to the advantage we can obtain by socializing information, resources, and the expectation of reciprocity."
        },
        competencies: {
            openness: "Openness",
            friendship: "Friendship",
            genuineTrust: "Genuine Trust",
            belonging: "Belonging",
            celebration: "Celebration",
            collectiveBeing: "Collective Being",
            flexibility: "Flexibility",
            cocreation: "Cocreation",
            altruism: "Altruism",
            selfDetermination: "Self-Determination",
            lucidity: "Lucidity",
            individualBeing: "Individual Being",
            selfExpression: "Self-Expression",
            convergence: "Convergence",
            purpose: "Purpose",
            interdependence: "Interdependence",
            concentration: "Concentration",
            mobilization: "Mobilization",
            clarity: "Clarity",
            access: "Access",
            reciprocity: "Reciprocity",
            sharing: "Sharing",
            recommendation: "Recommendation"
        }
    },
    es: {
        title: "Informe de Habilidades",
        loading: "Cargando su informe...",
        loadingAI: "Cargando recomendaciones de IA. Esto puede tardar unos minutos... Por favor espere...",
        loadingDimensions: "Cargando análisis de dimensiones...",
        loadingFactors: "Cargando análisis de factores...",
        loadingChart: "Cargando gráfico...",
        error: "Error al cargar el informe",
        tryAgain: "Intentar de nuevo",
        sections: {
            overview: "Visión General",
            details: "Detalles",
            recommendations: "Recomendaciones"
        },
        skills: {
            technical: "Habilidades Técnicas",
            soft: "Habilidades Blandas",
            business: "Habilidades de Negocio"
        },
        levels: {
            beginner: "Principiante",
            intermediate: "Intermedio",
            advanced: "Avanzado",
            expert: "Experto"
        },
        charts: {
            skillDistribution: "Distribución de Habilidades",
            strengthAreas: "Áreas de Fortaleza",
            improvementAreas: "Áreas de Mejora"
        },
        sourceSkillsDescription: `Source Skills es un marco compuesto por 23 factores, 
            que representa el potencial de colaboración con autoconciencia, desde la perspectiva de la gestión, 
            el liderazgo y el desarrollo humano y organizacional.

            Source Skills es el resultado de la investigación académica en negocios innovadores y genera valor en diversas aplicaciones, 
            enfocadas en la mentoría, la educación corporativa y la gestión empresarial, así como en consultoría dirigida a diversos sectores.

            En este diagnóstico, encuentras un retrato inicial de estos 23 factores, para que puedas reflexionar y 
            también potenciar tu propio viaje de liderazgo y autodesarrollo.

            Saludos,`,
        resultadoGeral: "Resultado General",
        indiceGeral: "Este es su índice general de habilidades Source Skills.",
        mapaDosFactors: "Mapa de 23 Factores",
        osFactors: "Los 23 Factores",
        journeyMessage: "Tu viaje de alto desarrollo y colaboración apenas está comenzando...",
        contactButton: "Contacta a marcelo@collabsoul.com",
        maxRetriesError: "No se pudieron cargar las recomendaciones después de varios intentos. Por favor, inténtelo de nuevo más tarde.",
        loadError: "Error al cargar las recomendaciones. Por favor, inténtelo de nuevo más tarde.",
        retryAttempt: "Intento {current} de {max}",
        dimensions: {
            alignment: "Alineación para Resultados",
            awareness: "Autoconciencia para el Beneficio Colectivo",
            creation: "Creación Colectiva",
            sharing: "Compartir Conocimiento"
        },
        dimensionsDescription: {
            alignment: "La dimensión \"Alineación para Resultados\" nos impulsa a involucrar a las personas en torno a un objetivo para lograr un resultado deseado, generando mayor pertenencia y protagonismo.",
            awareness: "La dimensión \"Autoconciencia para el Beneficio Colectivo\" nos invita a una mayor efectividad con los demás, comenzando por nosotros mismos, en un \"lugar\" interno que nos permite actuar con más lucidez, coherencia, autodeterminación y resiliencia.",
            creation: "La dimensión \"Creación Colectiva\" nos conecta con la creatividad y la innovación, no solo en la dimensión cognitiva sino principalmente en la fuerza del campo colectivo emergente.",
            sharing: "La dimensión \"Compartir Conocimiento\" nos conecta con el toque de conveniencia a la ventaja que podemos obtener al socializar información, recursos y la expectativa de reciprocidad."
        },
        competencies: {
            openness: "Apertura",
            friendship: "Amistad",
            genuineTrust: "Confianza Genuina",
            belonging: "Pertenencia",
            celebration: "Celebración",
            collectiveBeing: "Ser Colectivo",
            flexibility: "Flexibilidad",
            cocreation: "Cocreación",
            altruism: "Altruismo",
            selfDetermination: "Autodeterminación",
            lucidity: "Lucidez",
            individualBeing: "Ser Individual",
            selfExpression: "Autoexpresión",
            convergence: "Convergencia",
            purpose: "Propósito",
            interdependence: "Interdependencia",
            concentration: "Concentración",
            mobilization: "Movilización",
            clarity: "Claridad",
            access: "Acceso",
            reciprocity: "Reciprocidad",
            sharing: "Compartir",
            recommendation: "Recomendación"
        }
    }
};
