import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SourceSkillsAuthContext } from './Auth/SourceSkillsAuthContext';
import { sourceSkillsAuth } from '../components/Auth/firebaseSourceSkillsConfig';
import LanguageSelector from './LanguageSelector';
import './Sidebar.css';

// Definindo as traduções diretamente no componente por enquanto
const translations = {
  pt: {
    welcome: 'Bem-vindo',
    logout: 'Sair'
  },
  en: {
    welcome: 'Welcome',
    logout: 'Logout'
  },
  es: {
    welcome: 'Bienvenido',
    logout: 'Salir'
  }
};

const Sidebar = ({ onLanguageChange, currentLanguage }) => {
  const { user } = useContext(SourceSkillsAuthContext);
  const [isExpanded, setIsExpanded] = useState(true);
  const navigate = useNavigate();
  const t = translations[currentLanguage] || translations.pt; // Fallback para português

  const handleLogout = async () => {
    try {
      await sourceSkillsAuth.signOut();
      navigate('/source-skills-login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <>
      <div className="fixed-top-bar">
        <button 
          className="toggle-button" 
          onClick={toggleSidebar}
          aria-label="Toggle menu"
        >
          {isExpanded ? '✕' : '☰'}
        </button>
        <LanguageSelector
          currentLanguage={currentLanguage}
          onLanguageChange={onLanguageChange}
        />
      </div>
      
      <div className={`mobile-overlay ${!isExpanded ? '' : 'active'}`} onClick={toggleSidebar} />
      
      <div className={`sidebar ${!isExpanded ? 'collapsed' : 'expanded'}`}>
        <div className="sidebar-header">
          <img 
            src="/midia/source_skills_logo.png" 
            alt="Source Skills Logo" 
            className="logo"
            onClick={handleLogoClick}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className="sidebar-content">
          {user && (
            <div className="welcome-message">
              <h2>{t.welcome},</h2>
              <p className="user-name">{user.displayName || user.email}</p>
            </div>
          )}
          <button className="logout-button" onClick={handleLogout}>
            {t.logout}
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
