import React, { createContext, useState, useContext, useEffect } from 'react';

const LanguageContext = createContext();

export function LanguageProvider({ children }) {
    const [language, setLanguage] = useState(localStorage.getItem('surveyLanguage') || 'pt');

    useEffect(() => {
        const handleLanguageChange = (event) => {
            setLanguage(event.detail);
        };

        const handleStorageChange = (e) => {
            if (e.key === 'surveyLanguage') {
                setLanguage(e.newValue || 'pt');
            }
        };

        window.addEventListener('languageChange', handleLanguageChange);
        window.addEventListener('storage', handleStorageChange);
        
        return () => {
            window.removeEventListener('languageChange', handleLanguageChange);
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const changeLanguage = (newLanguage) => {
        localStorage.setItem('surveyLanguage', newLanguage);
        setLanguage(newLanguage);
        window.dispatchEvent(new CustomEvent('languageChange', { detail: newLanguage }));
    };

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
}

export function useLanguage() {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
}
