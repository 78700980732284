import React, { useState, useEffect } from 'react';
import '../layouts/source_skills_results/normalize.css';
import '../layouts/source_skills_results/webflow.css';
import '../layouts/source_skills_results/test-01-4072d8.webflow.css';
import '../layouts/source_skills_results/customReport.css';
import arrow from '../layouts/source_skills_results/images/arrow.svg';
import sourceSkillsLogo from '../layouts/source_skills_results/images/source-skills.svg';
import collabSoulLogo from '../layouts/source_skills_results/images/collabsoul.svg';
import FetchName from '../api/FetchName.tsx';
import { useLanguage } from '../contexts/LanguageContext';
import { reportTranslations } from '../translations/reportTranslations';
import MainLayout from '../layouts/MainLayout';
import { useSourceSkillsAuth } from '../components/Auth/SourceSkillsAuthContext';

import FetchAVGDimensionType from '../api/FetchAVGDimensionType.tsx';
import FetchAVGDimensionTypeWithoutDimensions from '../api/FetchAVGDimensionTypeWithoutDimensions.tsx';
import IndiceGeralGauge from './IndiceGeralGauge';
import DimensaoChart from './DimensaoChart';
import CompetenciasRadar from './CompetenciasRadar';
import { ThreeCircles } from 'react-loader-spinner';
import DimensoesRecomendacoesLoader from './ReportLoaders/DimensoesRecomendacoesLoader.jsx';
import FatoresRecommendationLoader from './ReportLoaders/FatoresRecommendationLoader.jsx';

// Atualizar o import
import brainImage from '../layouts/source_skills_results/images/brain.svg';

function SourceSkillsResultAi() {
  const { language, changeLanguage } = useLanguage(); // Usando changeLanguage também
  const { user } = useSourceSkillsAuth();
  const translations = reportTranslations[language] || reportTranslations.pt;

  const [state, setState] = useState({
    nome: '...',
    dimensoes_nome: [],
    dimensoes_valor: [],
    competencias_nome: [],
    competencias_valor: [],
    indice_geral: [],
    loading_indice: true,
    loading_dimensoes: true,
    loading_competencias: true,
    context: null,
    context_filter: null,
    guid_answer: null,
    token: null
  });

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const guid_answer = urlParams.get('guid');

  useEffect(() => {
    if (!user) {
      return;
    }

    if (guid_answer) {
      setState(prevState => ({ ...prevState, context: 'answer', context_filter: guid_answer, guid_answer: guid_answer }));
    }
    else if (token) {
      setState(prevState => ({ ...prevState, context: 'token', context_filter: token, token: token }));
    }

    async function fetchData() {
      console.log(state.context, state.context_filter)
      const name = await FetchName(state.context, state.context_filter);

      if (name) {
        setState(prevState => ({ ...prevState, nome: name.name }));
      }

      const dimensoes = await FetchAVGDimensionType(state.context, state.context_filter, "Dimensão");
      if (dimensoes) {
        setState(prevState => ({
          ...prevState,
          dimensoes_nome: dimensoes.value,
          dimensoes_valor: dimensoes.avg_substitution_value,
          loading_dimensoes: false
        }));
      }

      const competencias = await FetchAVGDimensionType(state.context, state.context_filter, "Competências Colaborativas");
      if (competencias) {
        setState(prevState => ({
          ...prevState,
          competencias_nome: competencias.value,
          competencias_valor: competencias.avg_substitution_value,
          loading_competencias: false
        }));
      }

      const indice_geral = await FetchAVGDimensionTypeWithoutDimensions(state.context, state.context_filter, "Competências Colaborativas");
      if (indice_geral) {
        setState(prevState => ({
          ...prevState,
          indice_geral: indice_geral.avg_substitution_value,
          loading_indice: false
        }));
      }
    }

    if (state.guid_answer || state.token) {
      console.log("fetching data");
      fetchData();
    }
  }, [state.guid_answer, state.token, user, language]);

  useEffect(() => {
    // Atualizar o idioma no localStorage e disparar evento
    const currentLanguage = localStorage.getItem('surveyLanguage');
    if (currentLanguage !== language) {
      localStorage.setItem('surveyLanguage', language);
      window.dispatchEvent(new CustomEvent('languageChange', { detail: language }));
    }
  }, [language]);

  return (
    <MainLayout style={{ padding: 0, margin: 0, maxWidth: '100%' }}>
      <div className="source-skills-result-container" style={{ margin: 0, padding: 0 }}>
        <div className="source-skills-result-content">
          <div className="section center wf-section">
            <img src={arrow} alt="" className="arrow" />
            <div data-w-id="6b220cb1-4b9c-7fb0-75b9-53ff4a6c35a2" style={{ WebkitTransform: 'translate3d(0, 30PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 30PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 30PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 30PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0 }} className="hero-content" />
            <div className="columns w-row">
              <div className="column-2 w-col w-col-3 w-col-medium-3">
                <img src={sourceSkillsLogo} loading="lazy" alt="" className="image-5" />
                <img src={collabSoulLogo} loading="lazy" alt="" className="image-6" />
              </div>
              <div className="w-col w-col-9 w-col-medium-9">
                <img src={brainImage} loading="lazy" height={562} width={800} alt="" className="hero-image" />
              </div>
            </div>
          </div>

          <div className="w-container">
            <div className="w-row">
              <div className="w-col w-col-12">
                <h2 className="black-text-name">
                  <span id="nome">
                    {state.nome === '...' ? <ThreeCircles color="#F5D728" height={30} width={30} /> : state.nome}
                  </span>,
                </h2>
                <p>
                  {translations.sourceSkillsDescription}
                </p>
                <p className="black-text-name">Marcelo Ferreira de Castilho</p>
                <p>Cofounder CollabSoul</p>
              </div>
            </div>
          </div>

          <div className="w-container">
            <div className="w-row">
              <div className="w-col w-col-6">
                <h2 className="black-text">{translations.resultadoGeral}</h2>
                <p className="paragraph-3">{translations.indiceGeral}</p>
              </div>
              <div className="w-col w-col-6">
                <div id="chart_gauge">
                  {!state.loading_indice ? (
                    <IndiceGeralGauge indice_geral={state.indice_geral} />
                  ) : (
                    <div className="loading-container">
                      <ThreeCircles color="#F5D728" height={80} width={80} />
                      <p className="loading-text">{translations.loadingChart}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-container">
            <div className="w-row" id="row-dimensao">
              <div className="w-col w-col-12">
                {/* <img src="../layouts/source_skills_results/images/pexels-lukas-952360.png" width="341" sizes="(max-width: 479px) 96vw, 341px" srcset="images/pexels-lukas-952360-p-500.png 500w, images/pexels-lukas-952360.png 659w" alt="" class="image-2"> */}
                <div id="chart_1" className="chart_dimensao" >
                  {!state.loading_dimensoes ? (
                    <DimensaoChart 
                        dimensao_valores={state.dimensoes_valor} 
                        dimensao_colunas={state.dimensoes_nome} 
                    />
                  ) : (
                    <div className="loading-container">
                      <ThreeCircles color="#F5D728" height={80} width={80} />
                      <p className="loading-text">{translations.loadingDimensions}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-row">
              <div className="w-col w-col-12">
                <DimensoesRecomendacoesLoader
                  context_filter={state.context_filter}
                  loadingMessage={translations.loadingAI}
                />
                {/* <p className="paragraph-2">A dimensão “<strong>Autoconsciência em prol do Coletivo</strong>” nos convida a uma efetividade maior com os outros, começando por nós mesmos, em um “lugar” interno que nos habilita a agir com mais lucidez, coerência, autodeterminação e resiliência.</p>
                <p className="paragraph-2">A dimensão “<strong>Alinhando para um Resultado</strong>” nos impulsiona para engajar pessoas em torno de um objetivo para o alcance de um resultado almejado, gerando maior pertencimento e protagonismo.</p>
                <p className="paragraph-2">A dimensão “<strong>Compartilhamento de Conhecimento</strong>” nos conecta com o toque da conveniência a vantagem que podemos obter ao socializar informações, recursos e a expectativa de reciprocidade.</p>
                <p className="paragraph-2">A dimensão “<strong>Criação Coletiva</strong>” nos conecta com a criatividade e inovação, não só na dimensão cognitiva, mas principalmente na força do campo coletivo emergente.</p> */}
              </div>
            </div>
          </div>
          <div className="w-container">
            <div className="w-row">
              <div className="w-col w-col-12">
                <h2 className="black-text">{translations.mapaDosFactors}</h2>
                <div id="chart_23competencias" className="chart_23competencias" style={{
                  width: '100%',
                  maxWidth: '100%',
                  margin: '0 auto',
                  overflow: 'hidden'
                }}>
                  {!state.loading_competencias ? (
                    <CompetenciasRadar 
                      dimensao_valores={state.competencias_valor} 
                      dimensao_colunas={state.competencias_nome} 
                    />
                  ) : (
                    <ThreeCircles color="#F5D728" height={80} width={80} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-container">
            <div className="w-row">
              <div className="w-col w-col-12">
                <h2 className="black-text">{translations.osFactors}</h2>
                <FatoresRecommendationLoader 
                  guid_answer={state.context_filter}
                  loadingMessage={translations.loadingFactors}
                />
              </div>
            </div>
          </div>
          <div className="w-container">
            <div className="custom-report-section">
              <p className="custom-report-text">
                {translations.journeyMessage}
              </p>
              <div className="report-buttons">
                <a href="mailto:marcelo@collabsoul.com" style={{ textDecoration: 'none' }}>
                  <button className="report-button contact-us">
                    {translations.contactButton}
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="footer wf-section">
            <div className="wrap" />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default SourceSkillsResultAi;
