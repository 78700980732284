import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { sourceSkillsAuth } from './firebaseSourceSkillsConfig';
import { 
  GoogleAuthProvider, 
  signInWithPopup, 
  browserSessionPersistence, 
  browserLocalPersistence, 
  setPersistence,
  sendPasswordResetEmail 
} from 'firebase/auth';
import { SourceSkillsAuthContext } from './SourceSkillsAuthContext';
import LanguageSelector from '../LanguageSelector';
import { translations } from '../../translations/auth';
import './SourceSkillsLogin.css'; // Import the CSS file
import sourceSkillsLogo from '../../layouts/source_skills_results/images/source-skills.svg';

const GoogleIcon = () => (
  <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
    <path fill="none" d="M0 0h48v48H0z"/>
  </svg>
);

const SourceSkillsLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [error, setError] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [message, setMessage] = useState('');
  const [language, setLanguage] = useState('pt');
  const [rememberMe, setRememberMe] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(SourceSkillsAuthContext);

  useEffect(() => {
    if (user) {
      const userLanguage = user.language || 'pt';
      setLanguage(userLanguage);
    }
  }, [user]);

  const handleLanguageChange = async (newLanguage) => {
    setLanguage(newLanguage);
    if (user) {
      try {
        await sourceSkillsAuth.currentUser.updateProfile({
          language: newLanguage
        });
      } catch (error) {
        console.error('Error updating language preference:', error);
      }
    }
  };

  const handleRedirectAfterLogin = () => {
    const redirectUrl = localStorage.getItem('redirectAfterLogin');
    console.log('Redirect URL:', redirectUrl); // Debug
    
    if (redirectUrl) {
      localStorage.removeItem('redirectAfterLogin'); // Limpa o redirect após usar
      navigate(redirectUrl, { replace: true }); // Usando replace para evitar problemas com histórico
    } else {
      navigate('/', { replace: true });
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault(); // Previne comportamento padrão
    try {
      await setPersistence(sourceSkillsAuth, rememberMe ? browserLocalPersistence : browserSessionPersistence);
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(sourceSkillsAuth, provider);
      const user = result.user;
      
      if (!user.language) {
        await user.updateProfile({
          language: language
        });
      }
      
      handleRedirectAfterLogin();
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setError(translations[language].invalidCredentials);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Previne comportamento padrão
    setError('');
    setMessage('');

    try {
      await setPersistence(sourceSkillsAuth, rememberMe ? browserLocalPersistence : browserSessionPersistence);

      if (isRegistering) {
        const userCredential = await sourceSkillsAuth.createUserWithEmailAndPassword(email, password);
        const user = userCredential.user;
        await user.updateProfile({
          displayName: displayName,
          language: language
        });
        await user.sendEmailVerification();
        setMessage(translations[language].registrationSuccess);
      } else {
        const userCredential = await sourceSkillsAuth.signInWithEmailAndPassword(email, password);
        const user = userCredential.user;
        if (user.emailVerified) {
          handleRedirectAfterLogin();
        } else {
          setError(translations[language].verifyEmail);
          await sourceSkillsAuth.signOut();
        }
      }
    } catch (err) {
      console.error('Error:', err);
      setError(translations[language].invalidCredentials);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if (!email) {
      setError(t.resetPasswordError);
      return;
    }

    try {
      setIsResettingPassword(true);
      await sendPasswordResetEmail(sourceSkillsAuth, email);
      setMessage(t.resetPasswordSuccess);
      setError('');
    } catch (err) {
      console.error('Error sending reset email:', err);
      setError(t.resetPasswordError);
      setMessage('');
    } finally {
      setIsResettingPassword(false);
    }
  };

  // Remova este useEffect ou modifique para não redirecionar automaticamente
  useEffect(() => {
    if (user && user.emailVerified) {
      handleRedirectAfterLogin();
    }
  }, [user]);

  const t = translations[language];

  return (
    <div className="login-container">
      <div className="logo-container">
        <img 
          src={sourceSkillsLogo} 
          alt="Source Skills Logo" 
          className="source-skills-logo"
        />
        <span className="logo-text">
          SELF-AWARENESS TOWARDS<br />
          THE COLLECTIVE
        </span>
      </div>

      <LanguageSelector
        currentLanguage={language}
        onLanguageChange={handleLanguageChange}
        className="language-selector"
      />
      
      <div className="login-left-panel">
        <form className="login-form" onSubmit={handleSubmit}>
          <h1>{isRegistering ? t.register : t.login}</h1>
          <input
            type="email"
            placeholder={t.email}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder={t.password}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          {!isRegistering && (
            <div className="forgot-password">
              <a 
                onClick={handleForgotPassword}
                style={{ opacity: isResettingPassword ? 0.7 : 1 }}
              >
                {isResettingPassword ? '...' : t.forgotPassword}
              </a>
            </div>
          )}
          <div className="remember-me">
            <input
              type="checkbox"
              id="rememberMe"
              checked={rememberMe}
              onChange={e => setRememberMe(e.target.checked)}
            />
            <label htmlFor="rememberMe">{t.rememberMe}</label>
          </div>
          <button type="submit">{isRegistering ? t.register : t.login}</button>
          
          <div className="divider">
            <span>ou</span>
          </div>
          
          <button 
            type="button" 
            className="google-button"
            onClick={handleGoogleSignIn}
          >
            <GoogleIcon />
            {t.continueWithGoogle}
          </button>
          
          {error && <p className="error-message">{error}</p>}
          {message && <p className="success-message">{message}</p>}
          <p className="toggle-message" onClick={() => setIsRegistering(!isRegistering)}>
            {isRegistering ? t.alreadyHaveAccount : t.needAccount}
          </p>
        </form>
      </div>
      
      <div className="login-right-panel">
        <div className="welcome-text">
          <h1>Source Skills</h1>
          <p>{t.welcomeMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default SourceSkillsLogin;
