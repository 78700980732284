import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseSourceSkillsConfig = {
  apiKey: "AIzaSyCFKl-RyVv1umasuU259YARjxamwe9L2Cg",
  authDomain: "source-skills.firebaseapp.com",
  projectId: "source-skills",
  storageBucket: "source-skills.appspot.com",
  messagingSenderId: "953699532378",
  appId: "1:953699532378:web:6b22cd679ab25cad4be263",
  measurementId: "G-K1ZCTTNY9V"
};

const sourceSkillsApp = firebase.initializeApp(firebaseSourceSkillsConfig, "sourceSkillsApp");
const sourceSkillsAuth = sourceSkillsApp.auth();

export { sourceSkillsAuth };
